<template>
  <b-modal id="change-status-modal" size="lg" centered hide-header hide-footer @hidden="reset">
    <div class="modal-title">Change Status</div>
    <validation-observer ref="observer">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <b-row>
        <b-col lg="6" sm="12">
          <base-select
            label="Change Status"
            :options="changeStatusOptions"
            field="status"
            :value="status"
            @change-value="setValue"></base-select>
        </b-col>
      </b-row>
      <div v-if="status === 1" key="1">
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Facility Amount" rules="required" v-slot="{ errors }">
              <base-input
                label="Facility Amount (Up to)"
                placeholder="Enter Facility Amount (Up to)"
                type="number"
                :min="0"
                field="fundInput"
                :value="fundInput"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
      <!-- qsdads -->

      <div v-if="status === 2" key="2"></div>

      <!-- qsdads -->

      <div v-if="status === 3" key="3"></div>
      <div v-if="status === 4" key="4">
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Cost Price" rules="required" v-slot="{ errors }">
              <!-- <base-select
                label="Cost Price"
                :options="costPriceOptions"
                field="costPrice"
                :value="costPrice"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-select> -->
              <base-input
                label="Cost Price"
                placeholder="Enter Cost Price"
                type="number"
                field="costPrice"
                :value="costPrice"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Sale Price" rules="required" v-slot="{ errors }">
              <base-input
                label="Sale Price"
                placeholder="Enter Sale Price"
                type="number"
                field="salePrice"
                :value="salePrice"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Repayment Schedule" rules="required" v-slot="{ errors }">
              <base-select
                label="Repayment Schedule"
                :options="repaymentScheduleOptions"
                field="repaymentSchedule"
                :value="repaymentSchedule"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col lg="6" sm="12">
            <validation-provider name="Discount Rate" rules="required" v-slot="{ errors }">
              <base-input
                label="Discount Rate"
                placeholder="Enter Discount Rate"
                type="number"
                field="discountRate"
                :value="discountRate"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Murabaha Rate" rules="required" v-slot="{ errors }">
              <base-input
                label="Murabaha Rate"
                placeholder="Enter Murabaha Rate"
                type="number"
                field="murabahaRate"
                :value="murabahaRate"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col lg="6" sm="12">
            <validation-provider name="Offer Letter" rules="required" v-slot="{ errors }">
              <base-input
                label="Offer Letter"
                :placeholder="'Upload your file here'"
                type="file"
                field="offerLetter"
                :value="offerLetter && offerLetter.name ? offerLetter.name : ''"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
      <div v-if="status === 5" key="5">
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Start Date" rules="required" v-slot="{ errors }">
              <base-input
                label="Start Date (Due)"
                placeholder="Enter Start Date (Due)"
                type="date"
                field="startDate"
                :value="startDate"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <base-input
              label="Receipt"
              :placeholder="'Upload your file here'"
              type="file"
              accept="application/pdf"
              field="receipt"
              :value="receipt && receipt.name ? receipt.name : ''"
              @change-value="setValue"
              :disabled="loading"></base-input>
          </b-col>
        </b-row>
      </div>
      <div v-if="status === 7" key="7">
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Notes" rules="required" v-slot="{ errors }">
              <base-input
                label="Notes"
                placeholder="Enter Notes"
                type="text"
                field="notes"
                :value="notes"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
      </div>

      <div v-if="status === 8 || status === 9" key="8">
        <b-row v-if="status === 9">
          <b-col lg="6" sm="12">
            <validation-provider name="Notes" rules="required" v-slot="{ errors }">
              <base-input
                label="Notes"
                placeholder="Enter Notes"
                type="text"
                field="notes"
                :value="notes"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Application Scoring" rules="required" v-slot="{ errors }">
              <base-select
                label="Application Scoring"
                :options="scoringOptions.filter((each) => !each.id || each.id !== 'NEED-IMPROVEMENT')"
                field="applicationScoring"
                :value="applicationScoring"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col lg="6" sm="12">
            <validation-provider name="File Type" rules="required" v-slot="{ errors }">
              <base-select
                label="File Type"
                :options="fileTypeOptions"
                field="fileType"
                :value="fileType"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <p>
              <a href="/CohortsGistsTemplate.xlsx">Cohorts and GistsTemplate</a>
            </p>
            <validation-provider name="Upload File" rules="required" v-slot="{ errors }">
              <base-input
                label="Upload File (one file containing all sheets)"
                :placeholder="'Upload your file here'"
                type="file"
                accept=".xlsx"
                field="uploadedFile"
                :value="uploadedFile && uploadedFile.name ? uploadedFile.name : ''"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <!--        <b-row>-->
        <!--          <b-col lg="6" sm="12">-->
        <!--            <base-input-->
        <!--              label="Upload Cohort File"-->
        <!--              :placeholder="'Upload your cohort file here'"-->
        <!--              type="file"-->
        <!--              accept=".csv"-->
        <!--              field="uploadedCohortFile"-->
        <!--              :value="-->
        <!--                uploadedCohortFile && uploadedCohortFile.name-->
        <!--                  ? uploadedCohortFile.name-->
        <!--                  : ''-->
        <!--              "-->
        <!--              @change-value="setValue"-->
        <!--              :disabled="loading"-->
        <!--            ></base-input>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row v-for="(chart, index) in charts" :key="index">
          <b-col lg="4" sm="12">
            <validation-provider :name="`Chart ${index + 1}`" rules="required" v-slot="{ errors }">
              <base-select
                :label="`Chart ${index + 1}`"
                :options="getChartOptions(chart)"
                field="chart_id"
                :value="chart.id"
                @change-value="(val, field) => setChartValue(val, field, index)"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
            </validation-provider>
          </b-col>
          <b-col lg="4" sm="12">
            <validation-provider :name="`Label ${index + 1}`" rules="required" v-slot="{ errors }">
              <base-select
                placeholder="test1"
                :options="performanceOptions"
                :cus_style="chart.color ? `background-color:${chart.color};color:#260B19;` : ''"
                style="font-size: 12px; font-family: Roboto_Medium; margin-top: 1.8rem"
                @change-value="(val, field) => changeChartLabelColor(val, field, index)"
                :value="chart.pindex"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''">
              </base-select>
            </validation-provider>
          </b-col>

          <b-col lg="2" sm="12">
            <base-button
              style_variant="secondary"
              cus_style="font-size: 12px; font-family: Roboto_Medium;margin-top:1.8rem"
              :disabled="charts.length === 1"
              @onClick="removeChart(index)">
              REMOVE
            </base-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="4" md="4">
            <base-button
              style_variant="secondary"
              cus_style="font-size: 12px; font-family: Roboto_Medium;margin-bottom:1rem"
              :disabled="loading"
              @onClick="addChart">
              ADD CHART
            </base-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-lg="8" lg="4" offset-sm="6" sm="6" class="d-flex align-items-center">
          <base-button
            style_variant="primary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
            :loading="loading"
            :disabled="loading || !status"
            @onClick="submit">
            SUBMIT
          </base-button>
          <base-button
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="closeModal">
            CANCEL
          </base-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate/dist/vee-validate.full";

import ApplicationsService from "../../services/applications.service";
import EnumeratesService from "../../services/enumerates.service";
import ChartsService from "../../services/charts.service";

const options = [
  {
    id: 0,
    label: "Gathering",
    source: "GATHERING",
    target: ["SCREENING", "CANCELED"],
    endpoint: "",
  },
  {
    id: 1,
    label: "Screening",
    source: "SCREENING",
    target: ["QUALIFIED", "DISQUALIFIED", "CANCELED"],
    endpoint: "screen",
  },
  {
    id: 2,
    label: "Qualified",
    source: "QUALIFIED",
    target: ["EXECUTION", "CANCELED"],
    endpoint: "qualify",
  },
  {
    id: 3,
    label: "Not Qualified",
    source: "DISQUALIFIED",
    target: ["CANCELED"],
    endpoint: "disqualify",
  },
  {
    id: 4,
    label: "In-Execution",
    source: "EXECUTION",
    target: ["FUNDED", "CANCELED"],
    endpoint: "execute",
  },
  {
    id: 5,
    label: "Funded",
    source: "FUNDED",
    target: ["COMPLETED", "CANCELED"],
    endpoint: "fund",
  },
  {
    id: 6,
    label: "Complete",
    source: "COMPLETED",
    target: ["CANCELED"],
    endpoint: "complete",
  },
  {
    id: 8,
    label: "Qualified With Chart",
    source: "QUALIFIED",
    target: ["EXECUTION", "CANCELED"],
    endpoint: "qualify",
  },
  {
    id: 9,
    label: "Not Qualified With Chart",
    source: "DISQUALIFIED",
    target: ["CANCELED"],
    endpoint: "disqualify",
  },
  {
    id: 7,
    label: "Cancel",
    source: "CANCELED",
    target: [""],
    endpoint: "cancel",
  },
];

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    console.log("this.appl", this);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "ChangeStatusModal",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  emits: ["refresh-page"],
  props: ["application_data"],
  data() {
    return {
      error: null,
      loading: false,
      colorOptions: [
        { id: null, label: "Choose" },
        { id: "#E8DFF5", label: "Violet", color: "#E8DFF5" },
        { id: "#FAE3D4", label: "Orange", color: "#FAE3D4" },
      ],
      changeStatusOptions: [],
      scoringOptions: [],
      fileTypeOptions: [
        { id: null, label: "Choose file type" },
        { id: 1, label: "IMAGE" },
        { id: 3, label: "PDF" },
      ],
      costPriceOptions: [
        { id: null, label: "Choose cost price" },
        { id: 1, label: "Update" },
      ],
      repaymentScheduleOptions: [],
      chartList: [],
      status: null,
      fundInput: null,
      applicationScoring: null,
      notes: null,
      fileType: null,
      uploadedFile: null,
      uploadedCohortFile: null,
      costPrice: null,
      salePrice: null,
      repaymentSchedule: null,
      discountRate: 20,
      murabahaRate: null,
      offerLetter: null,
      startDate: null,
      receipt: null,
      chart_id: null,
      chart_label: null,
      chart_color: null,
      charts: [{ id: null, label: null, color: null, insights: ["No Insights"] }],
      performanceOptions: [
        { id: null, label: "Choose" },
        { id: "1", label: "Low performing metric" },
        { id: "2", label: "Moderate performing metric" },
        { id: "3", label: "High performing metric" },
      ],
    };
  },
  methods: {
    changeChartLabelColor(val, field, index) {
      this.charts = this.charts.map((item) => this.addPerformance(item));
      const color = val == 1 ? "#FAE3D4" : val == 2 ? "#D7D7D7" : "#E8DFF5";
      const label = this.performanceOptions[val]?.label?.replace(/\([^)]+\)\s/, "");
      if (color && label) {
        this.charts[index]["color"] = color;
        this.charts[index]["label"] = label;
      }
    },
    addPerformance(item) {
      if (item.pindex || !item.label) return item;

      item.pindex = 0;

      // 1. Exact match for High and Low
      const performance = this.performanceOptions.find((perf) => {
        return perf.label === item.label;
      });
      if (performance) {
        item.pindex = performance.id;
      } else {
        // For Moderate (Low) and Moderate (High) match the first word
        if (item.label.startsWith("Moderate")) {
          item.pindex = item.color == "#FAE3D4" ? 2 : 3;
        } else {
          this.performanceOptions.push({
            id: this.performanceOptions.length,
            label: item.label + "         (Legacy)",
          });
          item.pindex = this.performanceOptions.length - 1;
        }
      }
      return item;
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      console.log("valid: ", valid);

      if (!valid) return;

      let payload = {};

      if (this.status === 1) {
        payload = {
          max_allowed: Number(this.fundInput),
        };
      } else if (this.status === 2) {
        payload = {
          score: this.applicationScoring,
          status: "QUALIFIED",
        };
      } else if (this.status === 3) {
        payload = {
          score: this.applicationScoring,
          status: "DISQUALIFIED",
        };
      } else if (this.status === 4) {
        payload = {
          cost_price: Number(this.costPrice),
          sale_price: Number(this.salePrice),
          discount: Number(this.discountRate),
          murabaha_rate: Number(this.murabahaRate),
          term: this.repaymentSchedule,
        };
      } else if (this.status === 5) {
        payload = {
          start_date: this.startDate,
          receipt: this.receipt ? await toBase64(this.receipt) : null,
        };
      } else if (this.status === 6) {
        payload = {};
      } else if (this.status === 7) {
        payload = {
          notes: this.notes,
        };
      } else if (this.status === 8 || this.status === 9) {
        payload = {
          score: this.applicationScoring,
          sheet: await toBase64(this.uploadedFile),
          gist: null,
          cohort: null,
          // gist: await toBase64(this.uploadedFile),
          // cohort: this.uploadedCohortFile
          //   ? await toBase64(this.uploadedCohortFile)
          //   : null,
          charts: this.charts,
          notes: this.notes,
          max_allowed: this.fundInput,
        };
      }

      if (this.status == 2 || this.status == 3) {
        this.loading = true;
        ApplicationsService.changeQualifiedDisQualified(this.application_data.id, payload)
          .then(() => {
            this.$emit("refresh-page");
            this.closeModal();
          })
          .catch(({ message }) => {
            this.error = message || "There was an error updating status.";
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      }

      if (this.status) {
        const status_rec = this.changeStatusOptions?.find((each) => each.id === this.status);

        if (status_rec && this.application_data) {
          this.loading = true;
          ApplicationsService.changeStatus(this.application_data.id, status_rec?.endpoint, payload)
            .then(() => {
              this.$emit("refresh-page");
              this.closeModal();
            })
            .catch(({ message }) => {
              this.error = message ? message : "There was an error updating status.";
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("change-status-modal");
      this.reset();
    },
    setChartValue(val, field, index) {
      if (field === "chart_id") this.charts[index]["id"] = val;
      if (field === "chart_label") this.charts[index]["label"] = val;
      if (field === "chart_color") this.charts[index]["color"] = val;
      this[field] = val;
    },
    setValue(val, field) {
      this[field] = val;
    },
    getChartOptions(chart) {
      return [
        { id: null, label: "Select Any" },
        ...this.chartList
          .filter((rec) => {
            if (rec && rec.id) {
              const chart_found = this.charts.find((chart_rec) => {
                return (
                  chart_rec &&
                  chart_rec.id &&
                  (!chart.id || (chart.id && chart_rec.id !== chart.id)) &&
                  chart_rec.id === rec.id
                );
              });
              if (chart_found) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          })
          .map((each) => {
            return {
              id: each.id,
              label: `${each.name} ${each.symbol ? `${each.symbol}` : ``} ${each.segment ? `(${each.segment})` : ``}`,
            };
          }),
      ];
    },
    addChart() {
      this.charts.push({
        id: null,
        label: null,
        color: null,
        insights: ["No Insights"],
      });
    },
    removeChart(index) {
      this.charts.splice(index, 1);
    },
    reset() {
      this.error = null;
      this.loading = false;
      // this.changeStatusOptions = [];
      this.status = null;
      this.fundInput = null;
      this.applicationScoring = null;
      this.notes = null;
      this.fileType = null;
      this.uploadedFile = null;
      this.costPrice = null;
      this.salePrice = null;
      this.repaymentSchedule = null;
      this.discountRate = null;
      this.murabahaRate = null;
      this.offerLetter = null;
      this.startDate = null;
      this.receipt = null;
      this.chart_id = null;
      this.chart_label = null;
      this.chart_color = null;
      this.charts = [{ id: null, label: null, color: null, insights: ["No Insights"] }];
    },
  },
  watch: {
    application_data(val) {
      if (val && val.state) {
        const source_rec = options.find((each) => each.source === val.state);
        let target_recs = options.filter((each) => source_rec.target.includes(each.source));
        target_recs.unshift({ id: null, label: "Choose Status" });
        this.changeStatusOptions = target_recs;
      }
    },
  },
  mounted() {
    this.loading = true;
    EnumeratesService.terms({ type: "APPLICATION" })
      .then((data) => {
        if (data && data.length) {
          this.repaymentScheduleOptions = data.map((item) => {
            return {
              id: item.id,
              label: item.name,
            };
          });
          this.repaymentScheduleOptions.unshift({
            id: null,
            label: "Choose repayment schedule",
          });
        }
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        EnumeratesService.scores({ type: "APPLICATION" })
          .then((data) => {
            if (data && data.length) {
              this.scoringOptions = data.map((item) => {
                return {
                  id: item.id,
                  label: item.name,
                };
              });
              this.scoringOptions.unshift({
                id: null,
                label: "Choose score",
              });
            }
          })
          .catch(({ message }) => {
            this.error = message;
          })
          .finally(() => {
            ChartsService.query()
              .then(({ objects }) => {
                this.chartList = objects;
              })
              .catch(({ message }) => {
                this.error = message;
              })
              .finally(() => {
                this.loading = false;
              });
          });
      });

    if (this.application_data && this.application_data.state) {
      const source_rec = options.find((each) => each.source === this.application_data.state);
      let target_recs = options.filter((each) => source_rec.target.includes(each.source));
      target_recs.unshift({ id: null, label: "Choose Status" });
      this.changeStatusOptions = target_recs;
    }

    if (this.fundInput == null)
      this.fundInput = Math.min(this.application_data?.requested, 0.3 * this.application_data?.arr);
  },
};
</script>

<style scoped>
#change-status-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
</style>
